/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useEffect } from "react";

import { Container, EmbedCode, Text } from "@atoms";
import { HeadingWIcon, SocialLinks } from "@molecules";
import chunkify from "@utils/chunkify";
import brand from "@utils/brand";

import classNames from "classnames";

const SocialFeed = ({ heading, embedCodes }) => {
  const { lgca, lakota } = brand;
  if (embedCodes && embedCodes.length > 0) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setTimeout(() => {
        if (
          typeof window !== "undefined" &&
          window.FB &&
          window.twttr &&
          window.instgrm
        ) {
          window.FB.XFBML.parse();
          window.twttr.widgets.load();
          window.instgrm.Embeds.process();
        }
      }, 1000);
    }, []);

    return (
      <div
        className={classNames("relative py-12 lg:pt-24", {
          "bg-brown": lakota,
          "bg-blue": lgca,
        })}
      >
        <div className="relative z-10">
          <Container>
            <div className="mb-6">
              <div className="mb-12">
                <HeadingWIcon icon="buffalo" heading={heading} inverse />
              </div>
              <div className="-mt-6 mb-4 flex flex-wrap items-center">
                <Text variant="h4" className="mr-4 font-bold text-red">
                  Follow Us
                </Text>
                <SocialLinks color={lgca ? "orange" : null} large />
              </div>
            </div>
            <div className="-mx-2 flex flex-wrap">
              {embedCodes &&
                chunkify(embedCodes, 3).map((a, i) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    className="w-full w-full px-2 sm:w-1/2 lg:mb-6 lg:w-1/3"
                  >
                    {a.map((code, ii) => {
                      return (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${i}_${ii}`}
                          className="mb-4 overflow-hidden rounded-lg shadow-xl"
                        >
                          <EmbedCode code={code} />
                        </div>
                      );
                    })}
                  </div>
                ))}
            </div>
          </Container>
        </div>
      </div>
    );
  }
  return null;
};

SocialFeed.defaultProps = {};

export default SocialFeed;
